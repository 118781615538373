import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Icon } from "@atoms";
import { AppLink } from "@base";
import { MobileHeader, Logo, Menu } from "@molecules";
import { useAppState } from "@state";
import { m, useScroll } from "framer-motion";
import useHeaderData from "@staticQueries/HeaderQuery";
import useLang from "@hooks/useLang";
import { navigate } from "gatsby";
import loadable from "@loadable/component";

const SearchModal = loadable(() => import("../molecules/SearchModal"));

const Header = ({ pageLang, locales }) => {
  const [{ layout }, dispatch] = useAppState();
  const lang = useLang();

  const { nav, button } = useHeaderData()[lang === "en" ? "en" : "es"];

  // Mobile nav triggers
  const { hideNav } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "4",
  };

  const [scroll, setScroll] = useState(0);
  const { scrollY } = useScroll();

  useEffect(() => {
    return scrollY.onChange(latest => {
      setScroll(latest);
    });
  }, []);

  const triggerModal = e => {
    dispatch({
      type: "openModal",
      title: "Search modal",
      description: "Search the Arts Council site",
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => <SearchModal />),
    });
  };
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isPreview = !!(
      urlParams.get("x-craft-live-preview") || urlParams.get("x-craft-preview")
    );
    if (pageLang && lang && pageLang !== lang && !isPreview) {
      navigate(locales[lang === "en" ? "en" : "es"]);
    }
  }, [pageLang, lang]);

  return (
    <header className="sticky top-0 left-0 right-0 z-[100] flex w-full items-center bg-black">
      <div className="mx-auto w-full max-w-[112rem] px-4 sm:px-6 md:px-8 lg:pr-0 lg:pl-10 xl:pl-16 xxl:max-w-8xl">
        <div
          className={classNames(
            "flex items-center justify-between duration-300",
            {
              "h-16 lg:h-18": scroll < 300,
              "h-12": scroll > 300,
            }
          )}
        >
          <AppLink to="https://artscouncilsc.org">
            <Logo scroll={scroll} />
          </AppLink>

          <nav
            aria-label="Header navigation"
            className="flex h-full gap-8 sm:gap-12"
          >
            <m.ul
              className={classNames(
                "flex h-full items-center space-x-8 duration-300 sm:space-x-12 lg:space-x-8"
              )}
            >
              {nav?.map(link => {
                return <Menu key={link.uid} scroll={scroll} {...link} />;
              })}
              <li
                key="language-button"
                className="flex items-center justify-center self-stretch lg:px-8"
              >
                <button
                  type="button"
                  onClick={() => dispatch({ type: "toggleLang" })}
                  className="flex items-center gap-2 border-b-2 border-white text-white duration-300 hover:border-green hover:text-green"
                >
                  <Icon name="globe" className="h-4 w-4" />
                  {lang === "en" && <span className="text-sm">EN</span>}
                  {lang === "es" && <span className="text-sm">ES</span>}
                </button>
              </li>
              <li
                key="search-button"
                className="flex items-center justify-center self-stretch border-white lg:border-l-2 lg:px-8"
              >
                <button
                  type="button"
                  aria-label="Open search modal"
                  onClick={triggerModal}
                  className="h-4 w-4"
                >
                  <Icon
                    name="search"
                    className="h-auto w-full text-white duration-300 hover:text-green"
                  />
                </button>
              </li>
              {button?.url && (
                <li
                  key={button.uid}
                  className="!ml-0 hidden self-stretch lg:flex"
                >
                  <AppLink to={button.url} className="h-full">
                    <div className="flex h-full items-center bg-green py-2 px-8 text-base font-bold duration-300 hover:bg-white xl:text-sm">
                      {button.text}
                    </div>
                  </AppLink>
                </li>
              )}
            </m.ul>

            <button
              type="button"
              className="flex h-full items-center justify-center lg:hidden"
              aria-label="Toggle mobile navigation"
              onClick={() => {
                dispatch({
                  type: hideNav ? "showNav" : "hideNav",
                });
              }}
            >
              {/* Hamburger Menu with animated SVG */}
              <span
                className={classNames("svg-icon w-[28px] duration-300", {
                  "text-green": hideNav,
                  "text-white": !hideNav,
                })}
              >
                <svg width="28" height="28" viewBox="0 0 34 24">
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 2L2 2" },
                      open: { d: "M31 2L9 22" },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                  <m.path
                    {...iconStyles}
                    d="M32 12L2 12"
                    variants={{
                      closed: { opacity: 1 },
                      open: { opacity: 0 },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 22L2 22" },
                      open: { d: "M31 22L9 2" },
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </svg>
              </span>
            </button>
          </nav>
        </div>
      </div>
      <MobileHeader nav={nav} button={button} scroll={scroll} />
    </header>
  );
};

Header.defaultProps = {};

export default Header;
